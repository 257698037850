import logo from "./assets/FAVICON.png";
import appStoreBadge from "./assets/appStoreBadge.png";
import playStoreBadge from "./assets/playStoreBadge.png";

function App() {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img
          src={logo}
          style={{
            height: "4em",
            marginBottom: "4em",
          }}
        />
        <h2
          style={{
            color: "white",
            fontSize: "16px",
          }}
        >
          Continue on the App
        </h2>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            flexDirection: "column",
          }}
        >
          <a href="https://apps.apple.com/in/app/cloutflow/id6444541233">
            <img
              src={appStoreBadge}
              style={{
                width: "10em",
              }}
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.cloutflow.creatorapp">
            <img
              src={playStoreBadge}
              style={{
                width: "10em",
              }}
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
